<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="话题名称">
                    <el-input v-model="queryParams.name" placeholder="请输入话题名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="r" label="排序" width="100" align="center"></el-table-column>
                <el-table-column prop="name" label="话题名称" width="150" align="center"></el-table-column>
                <el-table-column label="海报图" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" :class="`img1column${scope.$index}`" fit="cover" :src="scope.row.img" :preview-src-list="[scope.row.img]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="logo图" align="center" width="80" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;" :class="`img2column${scope.$index}`" fit="cover" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="desc" label="话题介绍" align="center"></el-table-column>
                <el-table-column label="是否显示" prop="is_show" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.is_show === 1">否</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_show === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否官方" prop="official" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.official === 1">否</el-tag>
                        <el-tag type="primary" v-if="scope.row.official === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            <!-- 新增 编辑 -->
            <el-dialog :title="title" :visible.sync="addVisible" width="600px" class="dialog_box" v-if="addVisible">
                <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                    <el-form-item label="排序" prop="r">
                        <el-input v-model="form.r" placeholder="数字越大越靠前" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="话题名称" prop="name">
                        <el-input v-model="form.name" placeholder="话题名称"></el-input>
                    </el-form-item>
                    <el-form-item label="话题介绍" prop="desc">
                        <el-input v-model="form.desc" placeholder="话题介绍"></el-input>
                    </el-form-item>
                    <el-form-item label="话题logo" prop="logo">
                        <el-input v-model="form.logo" placeholder="请输入话题logo链接"></el-input>
                        <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                        <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top: 20px;">
                            <img v-if="form.logo" :src="form.logo" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸750*376像素</span>
                    </el-form-item>
                    <!-- 图片上传 -->
                    <el-form-item label="话题海报" prop="img">
                        <el-input v-model="form.img" placeholder="请输入话题海报链接"></el-input>
                        <el-button @click.prevent="choiceImg('img')">选择图片</el-button>
                        <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top: 20px;">
                            <img v-if="form.img" :src="form.img" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸750*376像素</span>
                    </el-form-item>
                    <el-form-item label="是否官方" prop="official" style="margin-top: -5px;">
                        <el-radio-group v-model="form.official">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否显示" prop="is_show" style="margin-top: -5px;">
                        <el-radio-group v-model="form.is_show">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">上线</el-radio>
                            <el-radio :label="1">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 删除 -->
            <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
                <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="removeVisible = false">取 消</el-button>
                    <el-button type="primary">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 选择图片对话框 -->
            <div class="img">
                <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['社区', '话题管理'],
            platformList: [{
                ty: '1',
                name: '可用'
            },
            {
                ty: '2',
                name: '不可用'
            },
            ],
            pageInfo: {},
            input: '',
            mode: 'add',
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            form: {

            },
            confirmContent: '',
            queryParams: {
                ctime: '',
                etime: '',
                searchName: '',
                page: 1,
                pagesize: 10
            },
            pickerOptions: {
                disabledDate(time) {
                }
            },
            title: '',
            header: {},
            // 验证
            rules: {
                r: [{
                    required: true,
                    message: '序号不能为空',
                    trigger: 'blur'
                }],
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                img: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                name: [{
                    required: true,
                    message: '话题名称不能为空',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '话题介绍不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                official: [{
                    required: true,
                    message: '是否官方不能为空',
                    trigger: 'blur'
                }],
                is_show: [{
                    required: true,
                    message: '是否显示不能为空',
                    trigger: 'blur'
                }]
            },
            list: '',
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        loadData() {
            this.loading = true;
            // 获取话题列表
            request.get('/community/column/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 编辑新增
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/community/column/add' : '/community/column/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');

                        return false;
                    }
                });
            };
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 新增
        add() {
            this.mode = 'add';
            this.title = '新增话题';
            this.form = {
                state: 2,
                official: 2,
                is_show: 2,
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑话题';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', time);
            console.log(888888, this.form)
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'img', res.data.uri);
            }
        },
        handleAvatarSuccess1(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/community/column/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
            this.confirmVisible = false;
        }
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 75px;
    line-height: 75px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 75px;
    line-height: 75px;
}
.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
